import { useEffect, useCallback } from 'react'
import { gsap } from 'gsap'

export const useTransition = (ref, { transitionState, selectors }) => {
  const playEnterTransition = useCallback(() => {
    const root = ref.current
    const darken = ref.current.querySelector(selectors.darken)

    const tl = gsap.timeline()
    tl.set(root, { autoAlpha: 1 })
    tl.fromTo(
      darken,
      { opacity: 0 },
      { opacity: 1, duration: 0.4, ease: 'power2.inOut' }
    )
  }, [ref, selectors.darken])

  const playExitTransition = useCallback(() => {
    const root = ref.current
    const darken = ref.current.querySelector(selectors.darken)

    const tl = gsap.timeline()
    tl.to(
      darken,
      { opacity: 0, duration: 0.4, delay: 0.5, ease: 'power2.inOut' },
      0
    )

    tl.set(root, { autoAlpha: 0 })
  }, [ref, selectors.darken])

  useEffect(() => {
    switch (transitionState) {
      case 'in':
        playEnterTransition()
        break
      case 'out':
        playExitTransition()
        break
      default:
        break
    }
  }, [transitionState, playEnterTransition, playExitTransition])
}
