import { atom } from 'recoil'

/**
 * Store transition state here
 *
 * @usage
 * const [transition, setTransition] = useRecoilValue(transitionState)
 */

export const transitionState = atom({
  key: 'transitionState', // unique ID (with respect to other atoms/selectors)
  default: null,
})
