import { useStaticQuery, graphql } from 'gatsby'
import { get } from '@/utils/get'
import { getLink } from '@/storyblok/helpers/getLink'
import faviconImg from '@/assets/favicon.png'
import faviconDarkmodeImg from '@/assets/favicon-darkmode.png'
import { getRichText } from '@/storyblok/helpers/getRichText'
// import { getSlug } from '@/utils/getSlug'

/**
 * Pull in Prismic config and fallback to Gatsby config
 */

export const useSiteConfig = () => {
  const data = useStaticQuery(
    graphql`
      query {
        gatsbyConfig: site {
          siteMetadata {
            title
            subtitle
            description
            author
            siteUrl
            image
          }
        }
        global: allStoryblokEntry(filter: { slug: { eq: "global" } }) {
          edges {
            node {
              id
              full_slug
              content
            }
          }
        }
      }
    `
  )

  const gatsbyConfig = data.gatsbyConfig.siteMetadata || {}
  const storyblokConfig = get(data, 'global.edges')
    ? JSON.parse(data.global.edges[0].node?.content)
    : {}

  // const nav = []
  // storyblokConfig.header_links.forEach((item, index) => {
  //   nav.push(
  //     getLink({
  //       uid: item._uid,
  //       title: item.title,
  //       link: item.link,
  //       target: item.target,
  //     })
  //   )
  // })

  const siteTitle = storyblokConfig.title || gatsbyConfig.title

  const footer = {
    linksHeading: storyblokConfig.footer_links_heading,
    links: storyblokConfig.footer_links.map((item) => {
      return getLink({
        uid: item._uid,
        title: item.title,
        link: item.link,
        target: item.target,
      })
    }),
    notesHeading: storyblokConfig.footer_notes_heading,
    notes: getRichText(storyblokConfig.footer_notes),
    contactHeading: storyblokConfig.footer_contact_heading,
    contact: getRichText(storyblokConfig.footer_contact),
  }

  return {
    metadata: {
      title: siteTitle,
      description: storyblokConfig.description || gatsbyConfig.description,
      subtitle: storyblokConfig.subtitle || gatsbyConfig.subtitle,
      image: get(storyblokConfig, 'image.filename') || gatsbyConfig.image,
      siteUrl: gatsbyConfig.siteUrl,
    },
    favicon: {
      default: faviconImg,
      darkMode: faviconDarkmodeImg || faviconImg,
    },
    menu: {
      siteTitle,
      footer,
    },
    header: {
      // nav,
      title: siteTitle,
    },
    footer,
  }
}
