import { useRecoilState } from 'recoil'
import { darkModeState } from '@/store'
import { useMedia } from '@/hooks/useMedia'

/**
 * Stateful logic required to add a dark mode toggle to your website.
 * It utilizes localStorage to remember the user's chosen mode,
 * defaults to their browser or OS level setting using the prefers-color-scheme
 * media query.
 *
 * @ref https://usehooks.com/useDarkMode/
 */

export const useDarkMode = () => {
  // Use recoil state
  const [enabledState, setIsEnabled] = useRecoilState(darkModeState)
  // See if user has set a browser or OS preference for dark mode.
  // The usePrefersDarkMode hook composes a useMedia hook (see code below).
  const prefersDarkMode = usePrefersDarkMode()
  // If enabledState is defined use it, otherwise fallback to prefersDarkMode.
  // This allows user to override OS level setting on our website.
  const isEnabled =
    typeof enabledState !== 'undefined' ? enabledState : prefersDarkMode

  // Return enabled state and setter
  return [isEnabled, setIsEnabled]
}

//  usehooks.com/useMedia
export const usePrefersDarkMode = () => {
  return useMedia(['(prefers-color-scheme: dark)'], [true], false)
}
