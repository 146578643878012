import EventEmitter from '@/utils/EventEmitter'

const config = {
  durations: {
    in: 800,
    out: 800,
  },
}

const events = {
  TRANSITION: 'transition',
}

const states = {
  READY: 'ready',
  IN: 'in',
  OUT: 'out',
}

class TransitionService {
  canOut = false
  state = states.READY

  constructor() {
    Object.assign(this, EventEmitter)
  }

  get ready() {
    return this.state === states.READY
  }

  setState = (val) => {
    this.state = val
    this.emit(events.TRANSITION, val)
  };

  in = (route) => {
    return new Promise((resolve, reject) => {
      if (!this.ready || window.location.pathname === route)
        return reject(false)

      this.setState(states.IN)
      this.canOut = false

      setTimeout(() => {
        this.canOut = true
        resolve()
      }, config.durations.in)
    })
  }

  out = () => {
    if (!this.canOut || this.state !== states.IN) return

    return new Promise((resolve) => {
      this.setState(states.OUT)

      setTimeout(() => {
        this.setState(states.READY)
        resolve()
      }, config.durations.in)
    })
  }
}

// and also the service
let service = new TransitionService()
service.events = events

export default service
