import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './Error.styles.scss'

const Error = ({ title, children }) => (
  <div className={styles.Error}>
    <div className={styles.container}>
      <h1 className={styles.heading}>{title}</h1>
      <div className={styles.content}>{children}</div>
    </div>
  </div>
)

Error.defaultProps = {
  title: undefined,
  children: undefined,
}

Error.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default Error
