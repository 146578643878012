import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useStoryblok } from '@/storyblok/hooks/useStoryblok'
import Error from '@/templates/Error'
import Report from '@/storyblok/templates/Report.storyblok'

const templateTypes = {
  report: Report,
}

const NotFoundPage = ({ location }) => {
  const story = useStoryblok(null, location)

  // preview unpublished content
  if (story && story.content) {
    const Template = templateTypes[story.content.component]

    return Template ? (
      <Template story={story} />
    ) : (
      <div>Unpublished preview is not supported for this content type.</div>
    )
  }

  return (
    <Error title="Page Not found">
      <p>
        If you entered a web address please check it was correct, or{' '}
        <Link to="/">click here</Link> to go to the homepage.
      </p>
    </Error>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object,
}

export default NotFoundPage
