let EventEmitter = {}

EventEmitter.on = function (name, callback) {
  if (typeof callback !== 'function') {
    return
  }
  this._emitCallbacks = this._emitCallbacks || {}
  this._emitCallbacks[name] = this._emitCallbacks[name] || []
  this._emitCallbacks[name].push(callback)
}

//obj.off() - remove all listeners
//obj.off([name]) - remove all listeners from eventType:[name]
//obj.off([name], [callback]) - remove  [callback] from eventType:[name]
EventEmitter.off = function (name, callback) {
  if (!this._emitCallbacks) {
    return
  }

  if (!name && !callback) {
    this._emitCallbacks = null
  }

  if (name && !callback) {
    this._emitCallbacks[name] = null
  }

  if (name && callback && this._emitCallbacks[name]) {
    const i = this._emitCallbacks[name].indexOf(callback)
    if (i > -1) {
      this._emitCallbacks[name].splice(i, 1)
    }
  }
}

EventEmitter.emit = function (name, evt) {
  if (!(this._emitCallbacks && this._emitCallbacks[name])) {
    return
  }
  for (let callback of this._emitCallbacks[name]) {
    callback(evt)
  }
}

EventEmitter.set = function (propKey, val, eventKey = null) {
  this[propKey] = val
  this.emitIfChanged(propKey, val, eventKey)
}

// EventEmitter.emitIfChanged = function(propKey,  eventKey = null){
//   this._prev = this._prev || {}
//   if(this._prev[propKey] !== this[propKey]){this.emit(eventKey || propKey, this[propKey])}
//   this._prev[propKey] = this[propKey]
// }

EventEmitter.setEmit = function (key, val, eventKey = null) {
  if (this[key] !== val) {
    this[key] = val
    const event = eventKey || key
    this.emit(event, val)
  }
}

EventEmitter.removeEventListener = EventEmitter.off
EventEmitter.addEventListener = EventEmitter.on
EventEmitter.trigger = EventEmitter.emit

export { EventEmitter }
export default EventEmitter
