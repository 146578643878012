import { atom } from 'recoil'

/**
 * Store dark mode state
 *
 * @usage
 * const [enabledState, setEnabledState] = useRecoilValue(darkModeState)
 */

export const darkModeState = atom({
  key: 'darkModeState', // unique ID (with respect to other atoms/selectors)
  default: undefined,
})
