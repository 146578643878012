import React from 'react'
import PropTypes from 'prop-types'
import { RecoilRoot } from 'recoil'
import Layout from '../../layout'
import { useSiteConfig } from '../hooks/useSiteConfig'

/**
 * Storyblok Layout is a pre-wrap which pulls in any
 * Storyblok content to pass to the main Layout component
 */

const LayoutStoryblok = ({ path, pageContext, children }) => {
  const site = useSiteConfig()

  const page = {
    title: pageContext.title || '',
    path,
    description: pageContext.description,
    image: pageContext.image,
  }

  return (
    <RecoilRoot>
      <Layout site={site} page={page}>
        {children}
      </Layout>
    </RecoilRoot>
  )
}

LayoutStoryblok.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
  pageContext: PropTypes.object.isRequired,
}

export default LayoutStoryblok
