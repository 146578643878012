import { atom } from 'recoil'

/**
 * Store browser state here, update in ResizeService
 *
 * @usage
 * const { device } = useRecoilValue(browserState)
 */

export const browserState = atom({
  key: 'browserState', // unique ID (with respect to other atoms/selectors)
  default: {
    device: undefined, // default state
  },
})
