/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const TransitionService = require('@/services/TransitionService').default

exports.onRouteUpdate = () => {
  TransitionService.out()
}

/**
 * Implement React Helmet Provider
 */

const React = require('react')
const { HelmetProvider } = require('react-helmet-async')

exports.wrapRootElement = ({ element }) => {
  return <HelmetProvider>{element}</HelmetProvider>
}
