import { atom } from 'recoil'

/**
 * Store menu state here
 *
 * @usage
 * const [currentSlide, setCurrentSlide] = useRecoilValue(currentSlideState)
 */

export const currentSlideState = atom({
  key: 'currentSlideState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
