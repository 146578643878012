import { atom } from 'recoil'

/**
 * Store menu state here
 *
 * @usage
 * const [isMenuOpen, setIsMenuOpen] = useRecoilValue(menuState)
 */

export const menuState = atom({
  key: 'menuState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
